@import "../../responsiveMixins.scss";

.footerWrapper {
  width: 100%;
  height: 30px;
  //  background: rgba(255, 255, 255, 0.1);
  display: flex;
  .footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    @include xs {
      max-width: 290px;
    }
    @include sm {
      max-width: 320px;
    }
    @include smd {
      max-width: 550px;
    }
    @include md {
      max-width: 700px;
    }
    @include lg {
      max-width: 900px;
    }
    @include xl {
      max-width: 1000px;
    }
    @include xxl {
      max-width: 1100px;
    }
    .copy {
      color: #fff;
      font-size: 12px;
      font-weight: 300;
      width: 100%;
      text-align: center;
      margin: 0 auto;
      cursor: default;
    }
  }
}
