@import "../../responsiveMixins.scss";

.preloader {
  top: 0;
  right: 0;
  z-index: 999999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 100px;
  opacity: 0.99;
  height: 100vh;
  width: 100vw;
  position: absolute;
  cursor: default;

  background: linear-gradient(
    104.19deg,
    $dark 10.09%,
    $middle 100%,
    $light 100%
  );
  background-size: 300% 300%;
  -webkit-animation: Background 25s ease infinite;
  -moz-animation: Background 25s ease infinite;
  animation: Background 25s ease infinite;

  @-webkit-keyframes Background {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  @-moz-keyframes Background {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  @keyframes Background {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  img {
    position: relative;
    animation: smoke 1400ms ease-in-out forwards;
    animation-iteration-count: infinite;
    max-height: 100px;
    max-width: auto;
    @include xs {
      max-height: 120px;
      max-width: auto;
    }
    @include sm {
      max-height: 150px;
      max-width: auto;
    }
    @include md {
      max-height: 220px;
      max-width: auto;
    }
    @include lg {
      max-height: 220px;
      max-width: auto;
    }
    @include xl {
      max-height: 220px;
      max-width: auto;
    }
    @include xxl {
      max-height: 140px;
      max-width: auto;
    }
    @keyframes smoke {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.5);
      }
      100% {
        transform: scale(1);
      }
    }
  }
  .text {
    align-items: flex-start;
    display: flex;
    -webkit-box-reflect: below -20px linear-gradient(transparent, rgba(0, 0, 0, 0.2));
    span {
      font-size: 34px;
      font-weight: 900;
      text-shadow: 0px 1px 4px rgb(0 0 0 / 70%);

      text-transform: uppercase;
      animation: waviy 1s infinite;
      animation-delay: calc(0.1s * var(--i));
      color: #fff;
      position: relative;
      @for $i from 1 through 8 {
        &:nth-child(#{$i}) {
          animation-delay: 70ms * $i;
        }
      }
    }
  }
  @keyframes waviy {
    0%,
    40%,
    100% {
      transform: translateY(0);
    }
    20% {
      transform: translateY(-7px);
    }
  }
}
