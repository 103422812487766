@import "../../responsiveMixins.scss";

.cookiesDisclaimerWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $panel;
  font-family: "Raleway", sans-serif;
  color: #fff;
  transition: transform 0.3s ease-in-out;
  transform: translateY(50px);
  z-index: 999;
  opacity: 0.9;
  &.visible {
    transform: translateY(0);
  }
  .cookiesDisclaimer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 5px;
    font-size: 12px;
    line-height: 1;
    width: 100%;
    height: 100%;
    max-width: 1200px;
    margin: 0 auto;
    &:hover {
      cursor: default;
    }
    @include xs {
      max-width: 290px;
      font-size: 8px;
    }
    @include sm {
      max-width: 320px;
      font-size: 9px;
    }
    @include smd {
      max-width: 550px;
      font-size: 11px;
    }
    @include md {
      max-width: 700px;
      font-size: 11px;
    }
    @include lg {
      max-width: 900px;
      font-size: 11px;
    }
    @include xl {
      max-width: 1000px;
    }
    @include xxl {
      max-width: 1100px;
    }
    .accept {
      border: solid 1px #fff;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 1;
      font-weight: 400;
      color: #fff;
      background-color: $panel;
      transition: all 0.2s ease-in-out;
      &:hover {
        background-color: #fff;
        color: $dark;
        cursor: pointer;
      }
    }
  }
}
