@import "../../responsiveMixins.scss";

.headerWrapper {
  width: 100%;
  height: 50px;
  // background: rgba(255, 255, 255, 0.1);
  // background: rgba(255, 255, 255, 0.25);
  position: relative;
  display: flex;
  z-index: 99999;
  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    @include xs {
      max-width: 290px;
    }
    @include sm {
      max-width: 320px;
    }
    @include smd {
      max-width: 550px;
    }
    @include md {
      max-width: 700px;
    }
    @include lg {
      max-width: 900px;
    }
    @include xl {
      max-width: 1000px;
    }
    @include xxl {
      max-width: 1100px;
    }
    .logo {
      a {
        display: flex;
        align-items: center;
        column-gap: 10px;
        text-decoration: none;
        img {
          max-height: 30px;
          filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.4));
        }
        span {
          color: #fff;
          font-size: 24px;
          line-height: 24px;
          font-weight: 700;
          text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
        }
      }
    }
    .hamburger {
      border: 0;
      height: 40px;
      width: 40px;
      padding: 0.5rem;
      border-radius: 50%;
      background-color: transparent;
      cursor: pointer;
      transition: background-color 0.2s ease-in-out;
      position: absolute;
      top: 50%;
      right: 2.5px;
      transform: translateY(-50%);
      display: none;
      &:hover {
        background-color: $light;
      }
      @include xs {
        display: block;
      }
      @include sm {
        display: block;
        right: 20px;
      }
    }
    .menu {
      &.expanded {
        ul {
          @include xs {
            display: block;
            width: 100%;
          }
          @include sm {
            display: block;
            width: 100%;
          }
        }
      }
      ul {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        column-gap: 1.7rem;
        padding: 0;
        @include xs {
          display: none;
          position: absolute;
          top: 50px;
          left: 0;
          flex-direction: column;
          width: 100vw;
          height: calc(100vh - 50px);
          background-color: $panel;
        }
        @include sm {
          display: none;
          position: absolute;
          top: 50px;
          left: 0;
          flex-direction: column;
          width: 100vw;
          height: calc(100vh - 50px);
          background-color: $panel;
        }
        @include md {
          column-gap: 0.7rem;
        }
        @include lg {
          column-gap: 1rem;
        }
        li {
          list-style-type: none;
          font-size: 18px;
          &:hover {
            span {
              background-size: 100% 0.05em;
              transition: all 0.33s cubic-bezier(0.215, 0.61, 0.355, 1);
            }
          }
          @include xs {
            text-align: center;
            margin: 0;
          }
          @include sm {
            text-align: center;
            margin: 0;
          }
          @include md {
            font-size: 12px;
          }
          @include lg {
            font-size: 14px;
          }
          @include xl {
            //     margin: 0 0 0 1.5rem;
          }
          @include xxl {
            //    margin: 0 0 0 2rem;
          }
          &:hover {
            @include xs {
              background-color: $light;
              span {
                background: none;
              }
            }
            @include sm {
              background-color: $light;
              span {
                background: none;
              }
            }
          }
          span {
            text-decoration: none;
            display: block;
            width: 100%;
            color: #fff;
            transition: all 0.33s cubic-bezier(0.215, 0.61, 0.355, 1);
            background-position: left bottom;
            background: linear-gradient(#fff, #fff) bottom / 0 0.05em no-repeat;
            &:hover {
              cursor: pointer;
            }
            @include xs {
              color: #fff;
              font-size: 17px;
              width: 100%;
              padding: 1rem 0;
            }
            @include sm {
              color: #fff;
              font-size: 24px;
              width: 100%;
              padding: 1rem 0;
            }
          }
        }
      }
      .nav {
        list-style: none;
        display: flex;
        align-items: center;
        column-gap: 80px;
        @include xs {
          display: none;
        }
        @include sm {
          display: none;
        }
        @include smd {
          column-gap: 42px;
        }
        .navItem {
          a {
            text-decoration: none;
            color: #fff;
            transition: all 0.2s ease-in;
            text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
          }
          .active {
            font-weight: 800;
          }
        }
      }
    }
  }
}
