$dark: #0f0c29;
$middle: #302b63;
$light: #24243e;
$panel: #302c63;

// 0px — 320px: Mobile devices XS
$screen-xs-min: 0px;
$screen-xs-max: 320px;
// 321px — 480px: Mobile devices SM
$screen-sm-min: 321px;
$screen-sm-max: 480px;
// 481px — 768px: Small Tablets SMD
$screen-smd-min: 500px;
$screen-smd-max: 700px;
// 481px — 768px: iPads, Tablets MD
$screen-md-min: 701px;
$screen-md-max: 768px;
// 769px — 1024px: Small screens, laptops LG
$screen-lg-min: 769px;
$screen-lg-max: 1024px;
// 1025px — 1200px: Desktops, large screens XL
$screen-xl-min: 1025px;
$screen-xl-max: 1200px;
// 1201px and more —  Extra large screens, XXL
$screen-xxl-min: 1201px;

// Extra Small devices
@mixin xs {
  @media (min-width: #{$screen-xs-min}) and (max-width: #{$screen-xs-max}) {
    @content;
  }
}
// Small devices
@mixin sm {
  @media (min-width: #{$screen-sm-min}) and (max-width: #{$screen-sm-max}) {
    @content;
  }
}
// Small tablets
@mixin smd {
  @media (min-width: #{$screen-smd-min}) and (max-width: #{$screen-smd-max}) {
    @content;
  }
}
// Medium devices
@mixin md {
  @media (min-width: #{$screen-md-min}) and (max-width: #{$screen-md-max}) {
    @content;
  }
}
// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) and (max-width: #{$screen-lg-max}) {
    @content;
  }
}
// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) and (max-width: #{$screen-xl-max}) {
    @content;
  }
}
// Extra large devices
@mixin xxl {
  @media (min-width: #{$screen-xxl-min}) {
    @content;
  }
}

// // Custom devices
// @mixin rwd($screen) {
//   @media (min-width: $screen+'px') {
//       @content;
//   }
// }
