@import "./responsiveMixins.scss";

.appWrapper {
  width: 100%;
  height: 100%;
  background: linear-gradient(150deg, $dark 10.09%, $middle 100%, $light 100%);
  animation: gradient 10s infinite linear; // 10s - скорость анимации
  background-size: 2000%; // Можно менять и подбирать интенсивность
  @keyframes gradient {
    0% {
      background-position: 80% 0%;
    }
    50% {
      background-position: 20% 100%;
    }
    100% {
      background-position: 80% 0%;
    }
  }
  .app {
    min-height: 100vh;
    margin: 0;
    display: grid;
    grid-template-rows: auto 1fr auto;
    .header {
      width: 100%;
      min-height: 50px;
      position: static;
      top: 0;
      z-index: 9;
      background: $panel;
    }
    .mainContentWrapper {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      // background-image: url("https://images.unsplash.com/photo-1488521787991-ed7bbaae773c?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=b73302ddf5fabf9285df2da9c462efbb&auto=format&fit=crop&w=1350&q=80");
      // background-repeat: no-repeat;
      // background-size: cover;
      .mainContent {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        height: 100%;
        width: 100%;
        max-width: 1000px;
        margin: 0 auto;
        padding: 20px 0;
        z-index: 1;

        @include xs {
          max-width: 290px;
        }
        @include sm {
          max-width: 420px;
        }
        @include md {
          max-width: 700px;
        }
        @include lg {
          max-width: 900px;
        }
        @include xl {
          max-width: 1100px;
        }
        @include xxl {
          max-width: 1100px;
        }
      }
    }
    .footer {
      min-height: 30px;
      width: 100%;
      background: $panel;
      z-index: 9;
    }
  }
}
