@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;700;900&family=Raleway:wght@300;400;500;600;700;800;900&display=swap");
* {
  margin: 0;
  scroll-behavior: smooth;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

.notification-container {
  padding: 10px 15px !important;
}

@media (min-width: 0px) and (max-width: 320px) {
  .jodit-placeholder span {
    font-size: 10px !important;
  }
  .jodit-placeholder ul li {
    font-size: 10px !important;
  }
  .jodit-status-bar .jodit-status-bar__item {
    font-size: 7px !important;
  }
  .jodit-status-bar .jodit-status-bar__item > span {
    font-size: 7px !important;
  }
}
@media (min-width: 321px) and (max-width: 480px) {
  .jodit-placeholder span {
    font-size: 11px !important;
  }
  .jodit-placeholder ul li {
    font-size: 11px !important;
  }
  .jodit-status-bar .jodit-status-bar__item {
    font-size: 8px !important;
  }
  .jodit-status-bar .jodit-status-bar__item > span {
    font-size: 8px !important;
  }
}

.jodit-container a:-webkit-any-link {
  color: #fff;
}
.jodit-toolbar-button__text {
  color: #fff;
}
.jodit-toolbar__box {
  background-color: #302b63 !important;
}
.jodit-workplace {
  background-color: #302b63 !important;
  color: #fff;
}
.jodit-toolbar-button__icon > svg {
  fill: #ffffff !important;
}
.jodit-toolbar-button__trigger > svg {
  fill: #ffffff !important;
}
.jodit-status-bar {
  background-color: #302b63 !important;
}
.jodit-xpath > span > a,
.jodit-status-bar__item > a,
.jodit-status-bar__item > span {
  color: #ffffff !important;
}

.jodit-popup__content {
  background-color: #413a88 !important;
  border: 1px solid #fff !important;
  border-radius: 3px !important;
}

.slider-picker {
  display: flex;
  flex-direction: column;
  height: 29px;
}
.slider-picker > div:nth-child(2) {
  margin-top: -15px;
}

body {
  margin: 0;
  font-family: "Raleway", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*::-webkit-scrollbar {
  -webkit-appearance: none;
}
*::-webkit-scrollbar:vertical {
  width: 8px;
}
*::-webkit-scrollbar:horizontal {
  height: 8px;
}
@media (min-width: 0px) and (max-width: 480px) {
  *::-webkit-scrollbar:vertical {
    width: 4px;
  }
  *::-webkit-scrollbar:horizontal {
    height: 4px;
  }
}
*::-webkit-scrollbar-thumb {
  border-radius: 6px;
  border: 1px solid #978bc7; /* should match background, can't be transparent */
  background-color: #395ab7;
}
*::-webkit-scrollbar-track {
  background-color: #978bc7;
  /* border: 0px solid #978bc7; */
  border-radius: 6px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
